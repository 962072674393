import axios from 'axios';
import services from '@/utils/services';
// import { Message } from 'element-ui';
import { Notification } from 'element-ui';
import Cookies from 'js-cookie';

const FileSaver = require("file-saver");
const TIMEOUT = 5000000;
const headers = {
  'Content-Type': 'application/json',
};

function downloadAxiosRequest(name, params = {}) {
  if (Cookies.get('token')) {
    headers.token = Cookies.get('token');
  }

  return new Promise((resolve, reject) => {
    axios({
      timeout: TIMEOUT,
      headers,
      ...params,
    }).then((res) => {
      if (res.status < 200 || res.status >= 300) {
        reject(res);
      } else {
          let blob = res.data;
          let fileName = name.replace("/s3/","");
          FileSaver(blob, fileName);
      }
    });
  });
}

const normalRequest = {
  downloadPost: (url,name,data) => downloadAxiosRequest(name, {
    method: 'post',
    url,
    data,
    responseType: "blob",
  }),
};

const downloadRequest = (serviceName, name, params) => {
  if (!services[serviceName]) {
    return Promise.reject(new Error('Request name is not defined'));
  }
  const {
    method = 'get',
    url = '',
  } = services[serviceName];

  return normalRequest[method](url,name, params);
};

export default downloadRequest;
