<template>
  <div class="unit" :class="{'page-is-loading': pageIsLoading}">
    <Bread :list="bread"></Bread>
    <div class="unit-info">
      <div class="unit-info-title">
        <span :class="{'app-must-flag-red': unitInfo.mustFlag === '1','app-must-flag-blue': unitInfo.mustFlag === '0'}">
          <span>{{unitInfo.mustFlag === '1' ? '必須': '任意'}}</span></span>
        {{unitInfo.title}}
        <!-- <span :style="{color: unitInfo.mustFlag === '1' ? 'red' : 'green'}">
          {{unitInfo.mustFlag === '1' ? '（必須）': '（任意）'}}</span> -->
      </div>
      <div class="unit-info-wrap">
        <div class="unit-info-left">
          <el-image class="unit-info-img" :src="unitInfo.pic" alt="コース画像" fit="contain">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="unit-info-right">
<!--          <div class="unit-info-item">-->
<!--            <div class="unit-info-item-label">講座</div>-->
<!--            <div class="unit-info-item-value">{{unitInfo.courseName}}</div>-->
<!--          </div>-->
<!--          <div class="unit-info-item">-->
<!--            <div class="unit-info-item-label">教科</div>-->
<!--            <div class="unit-info-item-value">{{unitInfo.subjectName}}</div>-->
<!--          </div>-->
          <div class="unit-info-item" style="font-size:17px;white-space:pre-wrap; word-wrap:break-word;line-height:1.5em">
            <strong>{{unitInfo.abstract}}</strong>
          </div>
          <div class="unit-info-desc " v-if="unitInfo.unitDetail">{{unitInfo.unitDetail}}</div>
        </div>
      </div>
    </div>
    <!-- <div class="unit-apply">{{isLogin?'受講申請はこちらへ。':'ログインして受講申請ください。'}}</div> -->
    <div class="unit-detail">
      <!-- <div style="font-size:17px;white-space:pre-wrap; word-wrap:break-word;line-height:1.5em">{{unitInfo.unitDetail}}</div> -->
      <!-- <div class="unit-detail-item"
        v-for="(item, index) in unitInfo.detail" :key="index"
      >
        <div class="unit-detail-title">{{item.name}}</div>
        <div class="unit-detail-content">
          <div class="unit-detail-content-item-wrap" v-for="(i, idx) in item.value" :key="idx">
            <div class="unit-detail-content-item" v-if="typeof i === 'string'">
              · {{i}}
            </div>
            <div v-else class="unit-detail-content-item">
              <div class="unit-detail-content-item-title">
                {{`${idx+1}. ${i.name}`}}
              </div>
              <div class="unit-detail-content-item-value">
                {{i.value}}
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="unit-detail-item" v-if="test.history &&test.history.length">
        <br>
        <div class="unit-detail-title">単元テスト履歴</div>
        <div class="unit-detail-content">
          <div class="unit-detail-test-result">
            <span class="unit-detail-test-result-title">
              単元確認テスト結果
            </span>
            <span class="unit-detail-test-result-value">
              {{
                (test.status==="0" || test.status==="2")
                  ?'合格'
                  :'不合格'
              }}
            </span>
          </div>
          <el-table
            :data="test.history"
            style="width: 100%"
            :border="true"
          >
            <el-table-column
              prop="time"
              label="テスト開始時刻"
              min-width="20%"
            >
            </el-table-column>
            <el-table-column
              prop="result"
              label="結果"
              min-width="17%"
            >
              <template slot-scope="scope">
                {{testResultObj[scope.row.result]}}
              </template>
            </el-table-column>
            <el-table-column
              prop="rate"
              label="正解率"
              min-width="15%"
            >
            </el-table-column>
            <el-table-column
              prop="status"
              label="テスト状態"
              min-width="17%"
            >
              <template slot-scope="scope">
                {{testStatusObj[scope.row.status]}}
              </template>
            </el-table-column>
            <el-table-column header-align="center" align="center" label="詳細" min-width="31%">
             <template v-if="scope.row.status==='1'" slot-scope="scope">
              <el-button type="text" size="small" @click="goToResult(scope.row.studentTestId)">解答内容・解説</el-button>
            </template>
           </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="unit-detail-item">
        <div class="unit-detail-title">教材一覧
          <div style="float:right">
            <el-button native-type="button" type="warning" @click="markNotice()">！マークの説明</el-button>
          </div></div>
        <div class="unit-detail-content">
          <div class="app-list-sorter-wrap clearfix">
<!--            <el-alert-->
<!--              :title="'受講可能な講座は'+this.listTotal+'件です。'"-->
<!--              type="success"-->
<!--              :closable="false"-->
<!--            >-->
            <!-- </el-alert> -->
            <!-- <div class="app-list-sorter-page" style="text-align: right;margin-top:10px;">
              <span style="font-weight:bold;">

              </span>
            </div> -->
            <!-- <div class="app-list-sorter-page">
              <span style="font-weight:bold">{{this.listTotal}}</span>{{pageText}}
            </div>
            <div class="app-list-sorter-right">
              <span>並び替え</span>
              <el-select v-model="sortBy" size="medium" style="width: 110px">
                <el-option
                  v-for="item in sortByOptions"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </div> -->
          </div>
          <div v-loading="tableIsLoading">
            <div class="app-list-wrap">
              <div class="app-list" v-for="(item, index) in materialList" :key="index">
                <div class="app-list-left">
                  <div v-if="item.contentType != '01' && item.contentType != '03' && item.contentType != '08'">
                    <el-image class="app-list-left-img" :src="item.pic"
                              alt="コース画像" fit="contain" @click="item.studyStatus === '1' && download(item.id, item.contentUrl)">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>
                  <div v-if="item.contentType == '01'">
                    <el-image class="app-list-left-img" :src="item.pic"
                              alt="コース画像" fit="contain" @click="item.studyStatus === '1' && showVideo(item.id)">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>
                  <div v-if="item.contentType == '03'">
                    <el-image class="app-list-left-img" :src="item.pic"
                              alt="コース画像" fit="contain" @click="item.studyStatus === '1' && showPDF(item.id)">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>
                  <div v-if="item.contentType == '08'">
                    <el-image class="app-list-left-img" :src="item.pic"
                              alt="コース画像" fit="contain" @click="item.studyStatus === '1' && showHtml(item.id)">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>
                </div>
                
                <div class="app-list-right" v-if="item.mustFlag === '0' || item.studyStatus !== '0'">
                  <div>
                    <div v-if="item.contentType != '01' && item.contentType != '03' && item.contentType != '08'">
                      <div class="app-list-right-title-no-hover">
                        <span :class="{'app-must-flag-red': item.mustFlag === '1','app-must-flag-blue': item.mustFlag === '0'}">
                          <span>{{item.mustFlag === '1' ? '必須': '任意'}}</span></span>
                        <strong style="white-space:pre-wrap; word-wrap:break-word;">{{item.title}}</strong>
                        <strong style="color:red">{{item.studyStatus === '0'?'（前の教材を受講した後で、この教材を受講してください。）':''}}</strong>
                      </div>
                    </div>
                    <div v-if="item.contentType == '01'" style="margin-bottom: 10px">
                      <div class="app-list-right-title-no-hover">
                        <span :class="{'app-must-flag-red': item.mustFlag === '1','app-must-flag-blue': item.mustFlag === '0'}">
                          <span>{{item.mustFlag === '1' ? '必須': '任意'}}</span></span>
                        <strong style="white-space:pre-wrap; word-wrap:break-word;">{{item.title}}</strong>
                        <strong style="color:red">{{item.studyStatus === '0'?'（前の教材を受講した後で、この教材を受講してください。）':''}}</strong>
                      </div>
                      <el-button native-type="button" class="studypack-button-primary" type="primary" plain @click="showVideo(item.id)" :disabled="item.studyStatus ==='0'">この教材を開く</el-button>
                    </div>
                    <div v-if="item.contentType == '03'" style="margin-bottom: 10px">
                      <div class="app-list-right-title-no-hover">
                        <span :class="{'app-must-flag-red': item.mustFlag === '1','app-must-flag-blue': item.mustFlag === '0'}">
                          <span>{{item.mustFlag === '1' ? '必須': '任意'}}</span></span>
                        <strong style="white-space:pre-wrap; word-wrap:break-word;">{{item.title}}</strong>
                        <strong style="color:red">{{item.studyStatus === '0'?'（前の教材を受講した後で、この教材を受講してください。）':''}}</strong>
                      </div>
                      <el-button native-type="button" class="studypack-button-primary app-list-right-button" type="primary" plain @click="showPDF(item.id)" :disabled="item.studyStatus === '0'">この教材を開く</el-button>
                      <el-button native-type="button" class="app-list-right-button" type="success" plain @click="download(item.id, item.contentUrl)" :disabled="item.studyStatus === '0'">この教材をダウンロードする</el-button>
                    </div>
                    <div v-if="item.contentType == '08'" style="margin-bottom: 10px">
                      <div class="app-list-right-title-no-hover">
                        <span :class="{'app-must-flag-red': item.mustFlag === '1','app-must-flag-blue': item.mustFlag === '0'}">
                          <span>{{item.mustFlag === '1' ? '必須': '任意'}}</span></span>
                        <strong style="white-space:pre-wrap; word-wrap:break-word;">{{item.title}}</strong>
                        <strong style="color:red">{{item.studyStatus === '0'?'（前の教材を受講した後で、この教材を受講してください。）':''}}</strong>
                      </div>
                      <el-button native-type="button" class="studypack-button-primary app-list-right-button" type="primary" plain @click="showHtml(item.id)">この教材を開く</el-button>
                    </div>
                    <div class="app-list-right-desc">{{item.desc}}</div>
                    <div class="app-list-right-progress" v-if="item.mustFlag === '1'">
                      <div class="app-list-right-progress-text">
                        <span class="app-list-right-progress-title"><span>教材受講進捗</span></span>
                        <span class="app-list-right-progress-detail">{{item.scsStudyStatus == '1' ? '100' : '0'}}%</span>
                      </div>
                      <div class="app-list-right-progress-bar">
                        <Progress :value="item.scsStudyStatus == '1' ? 1 : 0" />
                      </div>
                    </div>
                    <div v-if="item.contentType == '01' && item.scsStudyStatus != '1'">
                      <el-button native-type="button" type="danger" plain @click="goEndTimeUpdate(item.id)" :disabled="item.studyStatus === '0'">動画視聴後、このボタンを押してください。</el-button>
                    </div>
                    <div v-if="item.contentType == '03' && item.scsStudyStatus != '1'">
                      <el-button native-type="button" type="danger" plain @click="goEndTimeUpdate(item.id)" :disabled="item.studyStatus === '0'">テキスト閲覧後、このボタンを押してください。</el-button>
                    </div>
                    <div v-if="item.scsStudyStatus == '1'">
                      <el-button native-type="button" type="danger" plain disabled>受講が完了しました。</el-button>
                    </div>
                  </div>
                </div>

                <!--必須フラグが「任意」あるいは　前のタスクがクリアする場合-->
                <div class="app-list-right" style="background-color:gray;" v-if="!(item.mustFlag === '0' || item.studyStatus !== '0')">
                  <div>
                    <div v-if="item.contentType != '01' && item.contentType != '03' && item.contentType != '08'">
                      <div class="app-list-right-title-no-hover">
                        <span :class="{'app-must-flag-red': item.mustFlag === '1','app-must-flag-blue': item.mustFlag === '0'}">
                          <span>{{item.mustFlag === '1' ? '必須': '任意'}}</span></span>
                        <strong style="white-space:pre-wrap; word-wrap:break-word;">{{item.title}}</strong>
                      </div>
                    </div>
                    <div v-if="item.contentType == '01'" style="margin-bottom: 10px">
                      <div class="app-list-right-title-no-hover">
                        <span :class="{'app-must-flag-red': item.mustFlag === '1','app-must-flag-blue': item.mustFlag === '0'}">
                          <span>{{item.mustFlag === '1' ? '必須': '任意'}}</span></span>
                        <strong style="white-space:pre-wrap; word-wrap:break-word;">{{item.title}}</strong>
                      </div>
                    </div>
                    <div v-if="item.contentType == '03'" style="margin-bottom: 10px">
                      <div class="app-list-right-title-no-hover">
                        <span :class="{'app-must-flag-red': item.mustFlag === '1','app-must-flag-blue': item.mustFlag === '0'}">
                          <span>{{item.mustFlag === '1' ? '必須': '任意'}}</span></span>
                        <strong style="white-space:pre-wrap; word-wrap:break-word;">{{item.title}}</strong>
                      </div>
                    </div>
                    <div v-if="item.contentType == '08'" style="margin-bottom: 10px">
                      <div class="app-list-right-title-no-hover">
                        <span :class="{'app-must-flag-red': item.mustFlag === '1','app-must-flag-blue': item.mustFlag === '0'}">
                          <span>{{item.mustFlag === '1' ? '必須': '任意'}}</span></span>
                        <strong style="white-space:pre-wrap; word-wrap:break-word;">{{item.title}}</strong>
                      </div>
                    </div>
                    <div class="app-list-right-desc">{{item.desc}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <el-pagination
            background
            :page-size="listSize"
            :pager-count="7"
            layout="prev, pager, next"
            :total="listTotal"
            @current-change="pageChange"
          >
           v-if="unitInfo.testFlg"
          </el-pagination> -->
        </div>
      </div>
      <div style="text-align:center;margin-bottom:15px" v-if="unitInfo.testFlg">
        <el-button native-type="button" type="danger" class="btn-font-large" @click="goTest()" :disabled="mustMaterialIsFinishFlag"> 単元確認テスト開始 </el-button>
      </div>
    </div>
    <!-- <hr> -->
    <el-button native-type="button" class="studypack-button-primary" type="primary" plain @click="goBack"> 単元一覧に戻る </el-button>
  </div>
</template>

<script>
import Bread from '@/components/Bread.vue';
import request from '@/utils/request';
import downloadRequest from '@/utils/downloadRequest';
import dayjs from 'dayjs';
import Progress from '@/components/Progress.vue';
import loadingMixin from '@/utils/loadingMixin';
import { Notification } from 'element-ui';


export default {
  name: 'Unit',
  components: {
    Bread,
    Progress,
  },
  inject: ['isLogin'],
  mixins: [loadingMixin],
  data() {
    return {
      bread: [],
      tableIsLoading: false,
      unitID: '',
      unitInfo: {
        // id: 0,
        title: '',
        pic: '',
        courseId: '',
        courseName: '',
        subjectId: '',
        subjectName: '',
        abstract: '',
        detail: '',
        mustFlag: '',
        courseControlFlag: ''
        // detail: [
        //   {
        //     name: '単元説明００１',
        //     value: [
        //       '例文：論理的思考の重要性、構成されている要素を理解できます。',
        //       '「OJT（on-the-job training）」とは何かが分かる',
        //       'OJT研修の手順を理解できる',
        //     ],
        //   },
        // ],
      },
      test: {
        status: "1",
        history: [],
      //   history: [
      //     {
      //       time: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      //       result: 1,
      //       rate: '80%',
      //       status: 1,
      //     },
      //     {
      //       time: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      //       result: 0,
      //       rate: '40%',
      //       status: 0,
      //     },
      //   ],
      },
      testResultObj: {
        0: '合格',
        1: '不合格',
        2: '単元テスト終了'
      },
      testStatusObj: {
        0: '解答中',
        1: '解答済',
        2: 'テスト中断',
        3: 'スキップ',
      },
      listTotal: 325,
      listPage: 1,
      listSize: 20,
      sortBy: '0',
      sortByOptions: [
        {
          key: '0',
          value: '新着順',
        },
        {
          key: '1',
          value: '人気順',
        },
        {
          key: '2',
          value: '評価順',
        },
      ],
      materialList: [],
      mustMaterialIsFinishFlag: false
    };
  },
  computed: {
    pageText() {
      const pageStart = (this.listSize * this.listPage) + 1;
      const pageEnd = this.listSize * (this.listPage + 1);
      return ` 件中 ${pageStart}～${pageEnd} 件を表示`;
    },
  },
  created() {
    this.init();
  },
  methods: {
    // getMaterialList(data) {
    //   this.tableIsLoading = true;
    //   request('getMaterialList', data).then((res) => {
    //     this.materialList = res;
    //   }).finally(() => {
    //     this.tableIsLoading = false;
    //   });
    // },
    // pageChange() {
    //   this.getMaterialList();
    // },
    init() {
      this.unitID = this.$route.params.id;
      request('getUnitDetail', {
        courseId: this.$route.params.courseId,
        subjectId: this.$route.params.subjectId,
        unitId: this.unitID,
      }).then((res) => {
        this.loadingClose();
        this.unitInfo = {
          title: res.unitName,
          pic: res.unitImagePath,
          courseId: this.$route.params.courseId,
          courseName: res.courseTitle,
          subjectId: this.$route.params.subjectId,
          subjectName: res.subjectTitle,
          abstract: res.unitOutline,
          unitDetail: res.unitDetail,
          testFlg: res.testFlg,
          mustFlag: res.mustFlag,
          courseControlFlag: res.courseControlFlag
        };
        const {
          count,
          studentTeachingMaterialResponseResourceList: list,
        } = res.studentTeachingMaterialResponseResourceList;

        this.listTotal = count;
        this.materialList = list.map((item) => ({
          id: item.teachingMaterialId,
          pic: item.teachingMaterialImagePath,
          title: item.teachingMaterialTitle,
          desc: item.teachingMaterialOutline,
          scsStudyStatus: item.scsStudyStatus,
          contentType:item.contentType,
          contentUrl:item.contentUrl,
          studyStatus: item.studyStatus,
          mustFlag: item.mustFlag,
        }));
        const findResult = list.find(item => item.mustFlag==='1' && item.scsStudyStatus!=='1')
        if(this.unitInfo.courseControlFlag === '1'){
          if(findResult !== undefined && findResult !== null){
            this.mustMaterialIsFinishFlag = true
          } else {
            this.mustMaterialIsFinishFlag = false
          }
        }
        const {
          // count: testCount,
          studentStudentTestResponseResourceList: testList,
        } = res.studentStudentTestResponseResourceList;
        this.test = {
          // 不合格1以外は合格とみなす。
          status: testList.find((item) => item.answerResult !== '1') ? '0' : '1',
          history: testList.map((item) => ({
            time: dayjs(item.answerStartTime).format('YYYY-MM-DD HH:mm:ss'),
            result: item.answerResult,
            rate: item.answerCorrectRate + "%",
            status: item.answerStatus,
            studentTestId: item.studentTestId
          })),
        };
        this.bread = [
          {
            path: '/',
            name: 'トップページ',
          },
          {
            path: `/course/${this.$route.params.courseId}`,
            name: this.unitInfo.courseName,
          },
          {
            path: `/subject/${this.$route.params.subjectId}/${this.$route.params.courseId}`,
            name: this.unitInfo.subjectName,
          },
          {
            name: this.unitInfo.title,
          },
        ];
      });
    },
    goBack() {
      this.$router.push({
        path: `/subject/${this.$route.params.subjectId}/${this.$route.params.courseId}`,
      }).catch(() => {});
    },
    goTest() {
      //const { courseId, subjectId } = this.$route.query;
      const courseId = this.$route.params.courseId;
      const subjectId = this.$route.params.subjectId;
      const unitId = this.$route.params.id;

      this.$router.push({
        path: `/test?courseId=${courseId}&subjectId=${subjectId}&unitId=${unitId}`,
      }).catch(() => {});
    },
    goMaterial(id) {
      const { courseId, subjectId } = this.$route.params;

      this.$router.push({
        path: `/material/${id}/${courseId}/${subjectId}/${this.$route.params.id}`,
      }).catch(() => {});
    },
    showPDF(id) {
      const { courseId, subjectId } = this.$route.params;
      const unitId = this.$route.params.id;
      const materialId = id;
      this.$router.push({
        path: `/showPDF/${courseId}/${subjectId}/${unitId}/${materialId}`,
      }).catch(() => {});
    },
    showVideo(id) {
      const { courseId, subjectId } = this.$route.params;
      const unitId = this.$route.params.id;
      const materialId = id;
      this.$router.push({
        path: `/showVideo/${courseId}/${subjectId}/${unitId}/${materialId}`,
      }).catch(() => {});
    },
    showHtml(id) {
      const { courseId, subjectId } = this.$route.params;
      const unitId = this.$route.params.id;
      const materialId = id;
      
      this.$router.push({
        path: `/showHtml/${courseId}/${subjectId}/${unitId}/${materialId}`,
      }).catch(() => {});
    },
    download(id,url) {
      downloadRequest('download', url, {
        courseId : this.$route.params.courseId,
        subjectId :  this.$route.params.subjectId,
        unitId :  this.$route.params.id,
        materialId : id,
      });
    },
    goEndTimeUpdate(id) {
      request('endTimeUpdate', {
          courseId : this.$route.params.courseId,
          subjectId :  this.$route.params.subjectId,
          unitId :  this.$route.params.id,
          materialId :  id,
        }).then((res) => {
          this.init()
          // this.materialList.forEach(it => {
          //   if(it.id == id){
          //     const position = this.materialList.indexOf(it);
          //     it.scsStudyStatus = res;
          //     this.$set(this.materialList, position, it);
              // const findResult = this.materialList.find(item => item.mustFlag==='1' && item.scsStudyStatus!=='1')
              // if(this.unitInfo.courseControlFlag === '1'){
              //   if(findResult === undefined || findResult === null){
              //     this.mustMaterialIsFinishFlag = false
              //   }
              // }
          //   }
          // })
        });
    },
    goToResult(studentTestId) {
      const params = {
        courseId: this.$route.params.courseId,
        subjectId: this.$route.params.subjectId,
        unitId: this.$route.params.id,
        studentTestId: studentTestId
      };
      request('noPassedAnsweres', params).then((res) => {
        window.testResult = res;
        this.$router.push({
        path: `/test/${studentTestId}/result?courseId=${this.$route.params.courseId}&subjectId=${this.$route.params.subjectId}&unitId=${this.$route.params.id}`,
      });
      });
      
    },
    markNotice(){
      Notification.closeAll()
      Notification({
        title: 'マーク説明',
        dangerouslyUseHTMLString: true,
        message: '<span class="app-must-flag-red"><span>必須</span></span>かならず受講完了する必要がある教材<p><span class="app-must-flag-blue"><span>任意</span></span>任意で受講すればよい教材',
        offset: 75,
        width: '450px',
      });
    }
  },
  mounted() {
    // this.getMaterialList();
  },
};
</script>

<style lang="less">
html {
  font-size:17px;
  line-height:1.5em;
}
.unit {
  margin: 30px 0;

  .unit-info {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .unit-info-title {
      text-align: center;
      font-size: 26px;
      margin-bottom: 60px;
    }
    .unit-info-wrap {
      display: flex;

      .unit-info-left {
        margin-right: 20px;
        box-sizing: border-box;
        // border: 1px solid #ccc;

        .unit-info-img {
          width: 320px;
          min-height: 230px;
          // height: 282px;
          .image-slot {
            min-height: 230px;
          }
        }
      }
      .unit-info-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        // border: 1px solid #ccc;
        box-sizing: border-box;
        justify-content: space-around;

        .unit-info-item {
          font-size: 17px;
          // border-bottom: 1px solid #ccc;

          .unit-info-item-label {
            padding: 8px;
            width: 180px;
            display: inline-block;
            // background-color: #eee;
          }
          .unit-info-item-value {
            padding: 8px;
            display: inline-block;
          }
        }
        .unit-info-desc {
          // flex: 1;
          padding: 8px;
          font-size: 15px;
          white-space:pre-wrap;
          word-wrap:break-word;
          line-height:1.5em;
          background-color: rgba(251, 204, 166);
        }
      }
    }
  }
  .unit-apply {
    background-color: #F1DCDB;
    padding: 20px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .unit-detail {
    .unit-detail-item {
      margin-bottom: 20px;

      .unit-detail-title {
        // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        font-size: 18px;
        font-weight: bold;
        // padding-bottom: 5px;
        margin-left: 10px;
        margin-bottom: 15px;
      }
      .unit-detail-content {
        font-size: 15px;
        // padding: 10px 0;
        .unit-detail-test-result {
          margin-left: 10px;
          margin-bottom: 15px;

          .unit-detail-test-result-title {
            display: inline-block;
            background-color: #0f3364;
            color: #fff;
            padding: 7px 18px;
            border-radius: 5px;
            margin-right: 15px;
            font-size: 15px;
          }
          .unit-detail-test-result-value {
            display: inline-block;
            font-size: 15px;
            margin-right: 20px;
            color: red;
          }

        }

        .unit-detail-content-item-wrap {
          .unit-detail-content-item {
            margin-bottom: 15px;

            .unit-detail-content-item-title {
              margin-bottom: 8px;
            }
            .unit-detail-content-item-value {
              font-size: 14px;
              color: #777;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .unit {
    .unit-info {
      .unit-info-wrap {
        flex-direction: column;
        .unit-info-left {
          margin-right: 0;
          text-align: center;
          .unit-info-img {
            height: 160px;
          }
        }
        .unit-info-right {
          .unit-info-item {
            .unit-info-item-label {
              width: 80px
            }
          }
        }
      }
    }
  }
}
</style>
